<template>
    <div >
        <!--        导航-->
      <rxNavBar title=""></rxNavBar>
        <div>
            <div class="meterDiv">
                <img :src="img.meterImg" class="electricLockImg">
                <div class="meterText">智能电表</div>
                <img :src="img.addressImg" class="addressImg">
                <div class="addressText">{{meterData.address}}</div>
            </div>
            <div class="details">
                <div class="moneyBalance">
                    <div class="moneyDetail">{{meterData.moneyBalance}}</div>
                    <div class="moneyText">电量余额/元</div>
                </div>
<!--                <div class="others">-->
                <div class="other">
                    <span class="spanText" id="span1">今日用电/元</span>
                    <div class="detail">{{meterData.electricOneDay}}</div>
                </div>
                <div class="other">
                    <span class="spanText" id="span2">总用电量/kw·h</span>
                    <div class="detail">{{meterData.electricInTotal}}</div>
                </div>
                <div class="other">
                    <span class="spanText" id="span3">电量余额/kw·h</span>
                    <div class="detail">{{meterData.electricBalance}}</div>
                </div>
<!--                    <div class="electricInTotal">{{meterData.electricInTotal}}</div>-->
<!--                    <div class="electricBalance">{{meterData.electricBalance}}</div>-->
<!--                </div>-->
            </div>

            <div @click="resetTariff()" class="rechargeButton">充值电费</div>
        </div>


    </div>
</template>

<script>
    import {
        NavBar
    }from 'vant'
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import {getStaffId, globaluserId, responseUtil} from "../../../libs/rongxunUtil";
    import {getUserPowerInfo} from "../../../getData/getData";
    export default {
        components:{
            [NavBar .name]:NavBar,
          rxNavBar
        },
        data(){
            return {
                img:{
                    meterImg:require("../../../assets/images/bigElectricMeter.png"),
                    addressImg:require("../../../assets/images/address.png"),
                    rechargeRecordingImg:require("../../../assets/images/rechargeRecording.png")
                },
                meterData:{
                    address:"家和花样年华一期-职工街100栋909…",
                    moneyBalance:"¥ 45.54",
                    electricOneDay:"10.33",
                    electricInTotal:"43.54",
                    electricBalance:"43.54"
                }
            }
        },
        mounted() {
            this.setHouse_id = ''
            this.roomHouse_id = ''
            this.equipment_id = ''
            this.isFirst = false
            this.meterData.address = this.$route.query.houseAddress
            this.setHouse_id = this.$route.query.setHouse_id
            this.roomHouse_id = this.$route.query.roomHouse_id
            this.uuid = this.$route.query.uuid
            //获取
            this.getUserPowerInfo()
        },
        methods:{
            leftReturn(){
                this.$router.go(-1)
            },

            //获取电量
            getUserPowerInfo(){
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.setHouse_id = that.setHouse_id
                initData.roomHouse_id = that.roomHouse_id
                initData.uuid = that.uuid
                getUserPowerInfo(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.meterData.moneyBalance = response.data.data.moneyBalance
                        that.meterData.electricOneDay = response.data.data.electricOneDay
                        that.meterData.electricInTotal = response.data.data.electricInTotal
                        that.meterData.electricBalance = response.data.data.electricBalance
                    })
                })
            },

            resetTariff() {
                this.$router.push({
                    name: 'resetTariff',
                    query: {

                    }
                })
            },

        }
    }

</script>
<style scoped>
.recordImg{
  position: relative;
  left: -3px;
  top: 2px;
}
.recordingSpan{
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  text-align: center;
  font-family: PingFangSC-Regular;
}
.recordingButton{
  width: 65px;
  margin:-22px 160px;
}
.rechargeButton{
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin:29px 15px;
  background: linear-gradient(to right,rgba(255, 194, 116, 1),rgba(255, 93, 59, 1));
  font-size: 16px;
  text-align: center;
  font-family: PingFangSC-Semibold;
  color: white;
  line-height: 45px;
}
#span3{
  width: 200px;
  position: relative;
  left: -7px;
}
#span2{
  width: 100px;
  position: relative;
  left: -7px;
}

#span1{
    width: 100px;
    position: relative;
}
.spanText{
  width: 70px;
  height: 14px;
  color: rgba(153, 153, 153, 1);
  font-size: 10px;
  text-align: left;
  font-family: PingFangSC-Regular;
  float: left;
  margin-left: 21px;
  margin-top: 45px;
}
.detail{
  position: relative;
  left: 27px;
  top: 10px;
  font-size: 20px;
  text-align: left;
  font-family: Oswald-DemiBold;
  /*font-weight: bolder;*/
}
.other{
  width: 115px;
  height: 138px;
  float: left;
}
.meterDiv{
  width: 345px;
  height: 89px;
  border-radius: 8px;
  background-color: #FFFFFF;
  margin-top: 15px;
  margin-left: 15px;
  background: linear-gradient(to left,#68AFFF 100%,#4982DF 100%);
}
.electricLockImg{
  width: 66px;
  height: 89px;
  float: left;
}
.meterText{
  width: 100px;
  height: 36px;
  color: rgba(255, 255, 255, 1);
  font-size: 25px;
  text-align: left;
  font-family: PingFangSC-Semibold;
  float: left;
  margin-top: 18px;
  margin-left: 2px;
}
.addressImg{
  top: 147px;
  width: 14.67px;
  height: 14.67px;
  float: left;
  margin-top: 60px;
  margin-left: -97px;

}
.addressText{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  top: 145px;
  width: 230px;
  height: 18px;
  color: rgba(255, 255, 255, 1);
  font-size: 13px;
  text-align: left;
  font-family: PingFangSC-Regular;
  float: left;
  margin-left: 6px;
  margin-top: 2px;
}
.details{
  width: 345px;
  height: 270px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  margin-left: 15px;
  margin-top: 14px;
}
.moneyBalance{
  height: 132px;
  width: 100%;
  border-bottom: 1px solid rgba(239, 239, 239, 1);
}
.moneyDetail{
  width: 100%;
  height: 47px;
  color: rgba(34, 34, 34, 1);
  font-size: 35px;
  text-align: center;
  font-family: Oswald-DemiBold;
  float: left;
  margin-top: 30px;
}
.moneyText{
  width: 66px;
  height: 17px;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-Regular;
  float: left;
  margin-top: 84px;
  margin-left: -200px;

}

</style>




